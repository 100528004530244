import React  from "react"
import Leaflet from "leaflet"
import { Map, Marker, Popup, TileLayer } from "react-leaflet"
import { Link, StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Container from "react-bootstrap/Container"

import Search from "./search"
import Filters from "./filters"
import Toggle from "./toggle"
import COLORS from "./colors"

const AwardeeIcon = (architect) => {
  const color = (architect) ? COLORS.dreihaus : COLORS.cnda  // Only Driehaus awardees have architects
  return new Leaflet.DivIcon({
    className: "marker-icon",
    html: `<h5 style="color: ${color}"><i class="fa fa-star"></i></h5>`
  })
}

const SearchIcon = () => {
  return new Leaflet.DivIcon({
    className: "marker-icon",
    html: `<h3><i class="fa fa-map-marker"></i></h3>`
  })
}

const AwardeeMarker = (props) => {
  return (
    <Marker position={props.center} icon={AwardeeIcon(props.architect)} opacity={0.8}>
      <Popup minWidth={300} maxWidth={500}>
        <Link to={`/${props.slug}`}>
          {
            props.image
              ? <Img
                  fluid={props.image.childImageSharp.fluid}
                  alt={`Featured image for ${props.name}`}
                />
              : null
          }
          <p>
            <i className="fa fa-fw fa-trophy"></i>&nbsp;
            <strong>{props.award}</strong>
          </p>
        </Link>
        <p>
          <strong>{props.name}</strong>
          {props.architect ? <small><br/>{props.architect}</small> : null}
        </p>
        <p>{props.address}</p>
      </Popup>
    </Marker>
  )
}

const AwardeeListNode = props => (
  <div className="card">
    {
      props.image
        ? <Link to={`/${props.slug}`}>
            <Img
              className="card-img-top"
              fluid={props.image.childImageSharp.fluid}
              alt={`Featured image for ${props.name}`}
            />
          </Link>
        : null
    }
    <div className="card-body">
      <Link to={`/${props.slug}`}>
        <h3 className="card-title">
          {props.name}
        </h3>
      </Link>
      <h4 className="card-title">
        <i
          className="fa fa-fw fa-star"
          style={{color: (props.architect) ? COLORS.dreihaus : COLORS.cnda}}
        ></i>&nbsp;
        {props.award}
      </h4>
      <p className="card-title">
        {props.address}
        {props.architect ? <span><br/>{props.architect}</span> : null}
      </p>
    </div>
  </div>
)

const AwardeeMarkers = (props) => {
  return props.markers.map(({node}) => {
    // Check that this marker is valid
    let matchesYearFilter = (props.year === 'all' || node.year === props.year)
    let nodeIsCnda = node.award ? true : false
    let filterIsCnda = (props.awardType === 'Chicago Neighborhood Development Award')
    let matchesAwardFilter = (props.awardType === 'all' || nodeIsCnda === filterIsCnda)

    if (node.lat && node.lon && matchesYearFilter && matchesAwardFilter) {
      let name = node.name ? node.name : node.project
      let architect = nodeIsCnda ? null : `${node.architect} for ${node.sponsor_owner}`
      let award = nodeIsCnda
        ? `${node.award} (${node.year})`
        : `${node.year} Driehaus Award, ${node.place} Place`
      if (props.view === 'list') {
        return (
          <AwardeeListNode
            name={name}
            slug={node.fields.slug}
            image={node.fields.imgPath}
            award={award}
            architect={architect}
            address={node.address}
          />
        )
      } else {
        return (
          <AwardeeMarker
            center={[node.lat, node.lon]}
            name={name}
            slug={node.fields.slug}
            image={node.fields.imgPath}
            award={award}
            architect={architect}
            address={node.address}
          />
        )
      }
    } else {
      return null
    }
  })
}

class AwardeeMap extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      year: 'all',
      awardType: 'all',
      view: 'map',
      searchPoint: [0, 0]
    }
    this.mapRef = React.createRef()
  }

  onSearchChange = (searchPoint) => {
    this.setState({ searchPoint })
    this.mapRef.current.leafletElement.setView(searchPoint, 14, {
      animate: true,
      pan: {
        duration: 0.5
      }
    })
  }

  onYearChange = (evt) => {
    const year = evt.target.value
    this.setState({ year })
  }

  onAwardTypeChange = (evt) => {
    const awardType = evt.target.value
    this.setState({ awardType })
  }

  onToggleMap = () => {
    console.log('Map selected')
    this.setState({view: 'map'})
  }

  onToggleList = () => {
    console.log('List selected')
    this.setState({view: 'list'})
  }

  render() {
    return (<StaticQuery query={graphql`
      query {
        allCndaGeocodedCsv {
          edges {
            node {
              lat
              lon
              name
              award
              year
              address
              fields {
                slug
                imgPath {
                  childImageSharp {
                    fluid(maxWidth: 600) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
        allDriehausGeocodedCsv {
          edges {
            node {
              lat
              lon
              project
              year
              place
              architect
              sponsor_owner
              address
              fields {
                slug
                imgPath {
                  childImageSharp {
                    fluid(maxWidth: 500, maxHeight: 300) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }`}

      render={data => {
        if (typeof window !== 'undefined') {
          return (
            <div>
              <Container className="mb-3">
                <Search
                  onSearchChange={this.onSearchChange}
                />
                <Filters
                  onYearChange={this.onYearChange}
                  onAwardTypeChange={this.onAwardTypeChange}
                />
                <Toggle
                  view={this.state.view}
                  onToggleMap={this.onToggleMap}
                  onToggleList={this.onToggleList}
                />
                <p
                  className="small font-italic"
                  style={(this.state.view !== 'map') ? {display: 'none'} : {}}
                >
                  Click stars on the map to learn more about individual projects
                </p>
              </Container>
              <Map
                ref={this.mapRef}
                center={[41.82, -87.62]}
                zoom={11}
                scrollWheelZoom={false}
                className="awardee-map"
                style={(this.state.view !== 'map') ? {display: 'none'} : {}}
              >
                <TileLayer
                  url='https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png'
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>"'
                />
                <Marker position={this.state.searchPoint} icon={SearchIcon()} opacity={0.8} />
                <AwardeeMarkers
                  view="map"
                  markers={data.allCndaGeocodedCsv.edges.concat(data.allDriehausGeocodedCsv.edges)}
                  year={this.state.year}
                  awardType={this.state.awardType}
                />
              </Map>
              <Container
                style={(this.state.view !== 'list') ? {display: 'none'} : {}}
              >
                <div className="card-columns">
                  <AwardeeMarkers
                    view="list"
                    markers={data.allCndaGeocodedCsv.edges.concat(data.allDriehausGeocodedCsv.edges)}
                    year={this.state.year}
                    awardType={this.state.awardType}
                  />
                </div>
              </Container>
            </div>
          )
        }
        return null
      }}
    / >)
  }
}

export default AwardeeMap
