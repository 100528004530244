import React from "react"

import GoogleMapsLoader from "./google"
import GoogleMapsApiKey from "./mapsApiKey"

GoogleMapsLoader.LIBRARIES = ['places', 'geocoder']
GoogleMapsLoader.KEY = GoogleMapsApiKey
GoogleMapsLoader.VERSION = '3.32';

export default class Search extends React.Component {
  constructor(props) {
    super(props)
    this.inputRef = React.createRef()
    this.geocoder = null
    this.google = null
  }

  componentDidMount() {
    GoogleMapsLoader.load((google) => {
      new google.maps.places.Autocomplete(this.inputRef.current)
      this.geocoder = new google.maps.Geocoder()
      this.google = google
    })
  }

  render() {
    return (
      <form onSubmit={this.submitForm} className="mb-3">
        <div className="input-group">
          <input
            ref={this.inputRef}
            onKeyDown={this.onKeyDown}
            className="form-control col-md-6"
            id="search-address"
            placeholder="Search for an address"
            type="text"
          />
          <div className="input-group-append">
            <button className="btn btn-outline-secondary" type="submit">Search</button>
          </div>
        </div>
      </form>
    )
  }

  submitForm = evt => {
    evt.preventDefault()
    this.search(this.inputRef.current.value)
  }

  onKeyDown = evt => {
    if (evt.key === 'Enter') {
      this.search(evt.target.value)
    }
  }

  search = address => {
    if (this.geocoder && this.google) {
      this.geocoder.geocode({'address': address}, (results, status) => {
        if (status === this.google.maps.GeocoderStatus.OK) {
          const lat = results[0].geometry.location.lat()
          const lng = results[0].geometry.location.lng()
          const point = [lat, lng]
          this.props.onSearchChange(point)
        } else {
          console.log('Google Maps API returned an error: ', status)
        }
      })
    } else {
      console.log('Google Maps Geocoder has not loaded yet. Wait and try again.')
    }
  }
}
