import React  from "react"
import { graphql } from "gatsby"
import Container from "react-bootstrap/Container"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/banner"
import AwardeeMap from "../components/map"


const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Awardees" />
    <Banner />
    <Container>
      <div className="row">
        <div className="col-sm-12 text-center">
          <br />
          <h1>
            Chicago Neighborhood Development Awards
          </h1>
          <h1 style={{marginTop: "1rem"}}>
            Awardee Map
          </h1>
          <hr />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <p>{data.site.siteMetadata.description}</p>
          <h2 class="mt-4 mb-3" id="awardee-map" style={{fontFamily: "Source Serif Pro"}}>Browse past awardees</h2>
        </div>
      </div>
    </Container>
    <AwardeeMap />
  </Layout>
)


export const query = graphql`
 query {
    site {
      siteMetadata {
        description
      }
    }
  }
`


export default IndexPage
