import React  from "react"


const Filters = (props) => {
  // Filter values
  // Update Array length and start year when adding new awardees!
  const years = Array.from(new Array(28), (x,i) => String(2024 - i))
  const awards = [
    {
      colorKey: 'driehaus',
      verboseName: 'Driehaus Award', 
    },
    {
      colorKey: 'cnda',
      verboseName: 'Chicago Neighborhood Development Award'
    }
  ]

  return (
    <form className="form-inline">
      <label for="filter-year">
        Year:
      </label>
      <select
        className="form-control my-3 mx-md-3"
        id="filter-year"
        onChange={props.onYearChange}
      >
        <option value="all" selected>All</option>
        {years.map((year) => <option value={year}>{year}</option>)}
      </select>
      <label for="filter-award-type">
        Award type:
      </label>
      <select
        className="form-control my-3 mx-md-3"
        id="filter-award-type"
        onChange={props.onAwardTypeChange}
      >
        <option value="all" selected>All</option>
        {awards.map((award) => (
          <option value={award.verboseName}>
            {award.verboseName}
          </option>
        ))}
      </select>
    </form>
  )
}

export default Filters
