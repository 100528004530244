import React from "react"

const Toggle = ({ view, onToggleMap, onToggleList}) => (
  <div className="btn-group btn-group-toggle my-3" data-toggle="buttons">
    <label className={`map-toggle btn btn-outline-secondary ${(view === 'map') ? 'active' : ''}`}>
      <input
        type="radio"
        name="options"
        id="option-map"
        autocomplete="off"
        onClick={onToggleMap}
        checked={view === 'map'}
      />
      <i className="fa fa-fw fa-map-marker"></i>&nbsp;
      Map view
    </label>
    <label className={`map-toggle btn btn-outline-secondary ${(view === 'list') ? 'active' : ''}`}>
      <input
        type="radio"
        name="options"
        id="option-list"
        autocomplete="off"
        onClick={onToggleList}
        checked={view === 'list'}
      />
      <i className="fa fa-fw fa-list"></i>&nbsp;
      List view
    </label>
</div>
)

export default Toggle
